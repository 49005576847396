@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
* {
  font-family: 'Quicksand', sans-serif;
}

body {
  background : url(/static/media/bg1.fc7ba83b.jpg) no-repeat center center fixed;
  background-size: cover;
  -webkit-background : cover;
  -moz-background : cover;
  -o-background : cover;
  background : cover;
  height: 100%;
  width: 100%;
}
